<template>
  <div>
    <div class="viewBg" v-html="info"></div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Introduce",
  data() {
    return {
      info: "",
    }
  },
  created() {
    // document.title = '舌诊评估'
    let userId = this.$route.query.userId
    console.log(userId)
    this.getcode(userId)
  },
  methods: {
    getcode(userId) {
      let url = "https://shezhen.hnxa168.com/newapi/app/getIntroduce?text=" + userId
      // let url = "https://shezhen.yuanjiwei.cn/api/app/getIntroduce?text=" + userId
      this.br = "<br/>"
      axios.get(url)
      axios.get(url)
          .then((result) => {
            console.log(result)
            this.info = result.data.result.info
          }).catch(error => {
        this.$router.replace("error")
      });
    }
  },
  beforeRouteLeave (to, from, next) {
    // 销毁组件，避免通过vue-router再次进入时，仍是上次的history缓存的状态
    this.$destroy(true)
    next()
  }
}
</script>

<style scoped>
.viewBg{
  background: rgba(255, 255, 255, 1);
  margin: 50px 32px 100px;
}
</style>
