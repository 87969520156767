<template>
  <div>
    <van-row class="image" justify="center" type="flex">
      <van-image
          :src="image"
          height="6rem"
          round
          width="6rem"
      />
    </van-row>
    <van-form @submit="onSubmit">
      <van-field
          v-model="username"
          :rules="[{ required: true, message: '请填写用户名' }]"
          label="用户名"
          name="username"
          placeholder="用户名"
      />

      <van-field
          v-model="age"
          :rules="[{ required: true, message: '请填写年龄' }]"
          label="年龄"
          name="age"
          placeholder="年龄"
          type="number"
      />

      <van-field
          v-model="height"
          :rules="[{ required: true, message: '请填写身高' }]"
          label="身高"
          name="height"
          placeholder="身高/cm"
          type="number"
      />

      <van-field
          v-model="weight"
          :rules="[{ required: true, message: '请填写体重' }]"
          label="体重"
          name="weight"
          placeholder="体重/kg"
          type="number"
      />

      <van-field label="性别" name="sex">
        <template #input>
          <van-radio-group v-model="sex" direction="horizontal">
            <van-radio name="1">男</van-radio>
            <van-radio name="2">女</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <div style="margin: 16px;">
        <van-button block native-type="submit" round type="info">提交</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>

import axios from 'axios';

export default {
  name: "Login",
  data() {
    return {
      image: "",
      username: '',
      age: '',
      height: "",
      weight: "",
      sex: "1",
      userId: ""
    };
  },
  created() {
    document.title = '个人信息'
    this.userId = this.$route.query.userId
    // let url = "https://shezhen.yuanjiwei.cn/api/app/getUserImage?id=" + this.userId
    // let url = "http://xiaoanweb.hnxa168.com/jeecg-boot/app/getUserImage?id=" + this.userId
    let url = "https://shezhen.hnxa168.com/newapi/app/getUserImage?id=" + this.userId
    axios.get(url)
        .then((result) => {
          //result 就是请求的结果
          console.log(result);
          // 把请求返回的数据存储到本地的data上
          let data = result.data;
          if (result.status === 200) {
            if (data.error === "1") {
              this.$router.replace("error")
            } else {
              // this.image = "https://shezhen.hnxa168.com/api/sys/common/static/" + data.image;
              if(this.image.includes("https://shezhen.hnxa168.com/api/sys/common/static/")){
                this.image = "https://shezhen.hnxa168.com/api/sys/common/static/" + data.image;
              }else{
                this.image =  data.image;
              }
            }
          } else {
            this.$router.replace("error")
          }
        }).catch(error => {
      this.$router.replace("error")
    });
  },
  methods: {
    onSubmit(values) {
      console.log('submit', values);
      console.log('submit', this.userId);
      // axios.post("https://shezhen.hnxa168.com/api/app/setUser?id=" + this.userId, values)
      // axios.post("http://xiaoanweb.hnxa168.com/jeecg-boot/app/setUser?id=" + this.userId, values)
      axios.post("https://shezhen.hnxa168.com/newapi/app/setUser?id=" + this.userId, values)
          .then((result) => {
            //result 就是请求的结果
            console.log(result);
            // 把请求返回的数据存储到本地的data上
            if (result.status === 200) {
              this.$router.replace("success")
            } else {
              this.$router.replace("error")
            }
          }).catch(error => {
        this.$router.replace("error")
      });
    },
  },
}
</script>

<style scoped>
.image {
  margin-top: 10px;
  margin-bottom: 50px;
}

</style>
