<template>
  <div class="view">
    <div class="view_title">
      <div class="view_title_item view_title_item_left">
        <span>健康服务</span>
        <div class="view_title_text_bg"/>
      </div>

      <div class="view_title_item title_right">
        <div class="view_title_item_riht_show">
          <img src="../../assets/images/jiankangfuwu.png">
        </div>
        <div class="view_title_item_riht_bg">
          <img src="../../assets/images/zitu_bg_icon.png">
        </div>
      </div>
    </div>

    <div class="view_content_bg">
      <van-tabs animated background="#00000000"
                color="rgba(23, 203, 188, 1)" line-height="3px"
                swipeable
                title-active-color="rgba(23, 203, 188, 1)"
                title-inactive-color="rgba(0, 0, 0, 1)">

        <van-tab title="中医建议">
          <div class="glossoscopyList">
            <div class="glossoscopytitle_one">
              <img alt="" class="glossoscopytex" src="../../assets/images/assessment11.png">
              <span class="glossoscopytitle">艾灸按摩</span>
            </div>

            <div class="explain">说明</div>
            <span class="fz24" v-html="statusStatement"></span>
            <div v-for="(item,itemvalue) in acupoints" :key="itemvalue">
              <div class="Recuperation">
                <img :src="item.imageUrl" alt="">
                <span class="pl30 fz28">{{ item.name }}<br/>
                        <span class=" fz20" v-html="item.genealogy"/><br/>
                        <span class=" fz20" v-html="item.position"/><br/>
                        <span class=" fz20" v-html="item.findingAcupointMethod"/><br/>
                        </span>
              </div>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <view_bottom></view_bottom>
  </div>
</template>

<script>
import view_bottom from "@/pages/healthy/view_bottom";
import axios from "axios";


export default {
  name: "HealthServices",
  components: {view_bottom},
  data() {
    return {
      statusStatement: "",
      acupoints: [
        {
          imageUrl: "",
          name: "",
          position: "",
          findingAcupointMethod: "",
          genealogy: "",
        }
      ]
    };
  },
  created() {
    // document.title = '舌诊评估'
    let userId = this.$route.query.userId
    console.log(userId)
    this.getcode(userId)
  },
  methods: {
    getcode(userId) {
      let url = "https://shezhen.hnxa168.com/newapi/app/details?" + userId
      // let url = "https://shezhen.yuanjiwei.cn/api/app/details?" + userId
      this.br = "<br/>"
      axios.post(url)
          .then((result) => {
            let details = result.data.result.details
            let json = JSON.parse(details.treatplanjson)
            this.statusStatement =
                json.treatplanjson.massageHealthCare.statusStatement + "</br>" +
                json.treatplanjson.massageHealthCare.acupointSelection + "</br>" +
                json.treatplanjson.massageHealthCare.method + "</br>"
            this.acupoints = json.treatplanjson.massageHealthCare.acupoints
          }).catch(error => {
        this.$router.replace("error")
      });
    },
  },
};
</script>

<style scoped>
.pl30 {
  padding-left: 30px;
}

.fz28 {
  color: #000000;
  font-size: 30px;
}

.fz20 {
  font-size: 20px;
  color: #999999;
}

.p15 {
  padding: 10px;
}

.Recuperation {
  display: flex;
  align-items: center;
  margin-top: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: rgba(245, 245, 245, 1);
  border-radius: 15px;
}

.Recuperation > img {
  width: 120px;
  height: 120px;
  display: block;
  border-radius: 25px;
  margin-left: 10px;
}

.explain {
  font-size: 28px;
  color: rgba(56, 56, 56, 1);
  padding: 20px 0;
}

.glossoscopyimg > img {
  width: 160px;
  height: 160px;
  display: block;
  border-radius: 50%;
}

.col9 {
  color: #999999;
}

.df {
  display: flex;
}

.jub {
  justify-content: space-between;
}

.pt30 {
  padding-top: 30px;
}

.coltt {
  color: rgba(0, 186, 173, 1);
}

.al {
  align-items: center;
}

.fz25 {
  font-size: 25px;
}

.fz24 {
  font-size: 26px;
  color: #999999;
  line-height: 45px;
  padding-top: 30px;
}

.glossoscopytitle_two {
  font-size: 40px;
  color: rgba(0, 186, 173, 1);
  font-weight: 650;
  text-align: center;
  /* border-bottom: 6px solid rgba(0, 186, 173, 1); */
  /* width: 50px; */
  margin: 0 auto 30px auto;
  /* padding-bottom: 45px; */
}

.glossoscopytitle_one {
  display: flex;
  align-items: center;
}

.glossoscopytex {
  width: 30px;
  height: 30px;
  /* background-color: #000000; */
  /* border-radius: 50%; */
  display: block;
  margin-right: 10px;
}

.glossoscopyList {
  background-color: #ffffff;
  border-radius: 25px;
  padding: 30px;
  margin-bottom: 30px;
}

.glossoscopytitle {
  font-size: 26px;
  color: #000000;
  font-weight: 650;
}

.glossoscopy {
  padding: 0.4rem;
  background: rgba(0, 186, 173, 1);
}

/deep/ .van-nav-bar .van-icon {
  color: black !important;
}

.assessment > img {
  width: 160px;
  height: 160px;
  display: block;
  border-radius: 50%;
}

.assessment {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.commentsItem {
  display: flex;
  justify-content: space-between;
}

.diagnostic_results_view {
  text-align: center;
  margin-top: 80px;
  margin-bottom: 40px;
}

.diagnostic_results_view span {
  padding: 8px 24px;
  border-radius: 38px;
  background: rgba(217, 250, 248, 1);

  font-size: 36px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 24px;
  color: rgba(0, 186, 173, 1);
  vertical-align: top;
}
</style>
