<template>
  <div class="view">
    <div class="view_title">
      <div class="view_title_item view_title_item_left">
        <span>骨骼</span>
        <div class="view_title_text_bg"/>
      </div>

      <div class="view_title_item title_right">
        <div class="view_title_item_riht_show">
          <img src="../../assets/images/guge.png">
        </div>
        <div class="view_title_item_riht_bg">
          <img src="../../assets/images/zitu_bg_icon.png">
        </div>
      </div>
    </div>
    <div class="view_content_bg">
      <div class="view_content_bg_title">详情数据</div>

      <div class="view_content_bg_item" @click="openValue('钙流失量')">
        <div class="view_content_bg_item_title" >
          <span>钙流失量</span><span>{{ bones1Index }}分</span><span>/100</span><span>{{ bones1 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="bones1" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>

      <div class="view_content_bg_item"  @click="openValue('骨质增生')">
        <div class="view_content_bg_item_title">
          <span>骨质增生</span><span>{{ bones2Index }}分</span><span>/100</span><span>{{ bones2 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="bones2" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>
      <div class="view_content_bg_item" @click="openValue('骨质疏松')">
        <div class="view_content_bg_item_title">
          <span>骨质疏松</span><span>{{ bones3Index }}分</span><span>/100</span><span>{{ bones3 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="bones3" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>
      <div class="view_content_bg_item" @click="openValue('骨密度')">
        <div class="view_content_bg_item_title">
          <span>骨密度</span><span>{{ bones4Index }}分</span><span>/100</span><span>{{ bones4 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="bones4" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>
      <div class="view_content_bg_item" @click="openValue('颈椎钙化')">
        <div class="view_content_bg_item_title">
          <span>颈椎钙化</span><span>{{ bones5Index }}分</span><span>/100</span><span>{{ bones5 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="bones5" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>
      <div class="view_content_bg_item" @click="openValue('腰椎钙化')">
        <div class="view_content_bg_item_title">
          <span>腰椎钙化</span><span>{{ bones6Index }}分</span><span>/100</span><span>{{ bones6 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="bones6" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>
      <div class="view_content_bg_item" @click="openValue('风湿')">
        <div class="view_content_bg_item_title">
          <span>风湿</span><span>{{ bones7Index }}分</span><span>/100</span><span>{{ bones7 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="bones7" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>
      <div class="view_content_bg_item" @click="openValue('骨源碱性磷酸酶')">
        <div class="view_content_bg_item_title">
          <span>骨源碱性磷酸酶</span><span>{{ bones8Index }}分</span><span>/100</span><span>{{ bones8 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="bones8" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>
      <div class="view_content_bg_item" @click="openValue('骨钙素')">
        <div class="view_content_bg_item_title">
          <span>骨钙素</span><span>{{ bones9Index }}分</span><span>/100</span><span>{{ bones9 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="bones9" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>

      <div class="view_content_bg_item" @click="openValue('长骨愈合状況')">
        <div class="view_content_bg_item_title">
          <span>长骨愈合状況</span><span>{{ bones10Index }}分</span><span>/100</span><span>{{ bones10 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="bones10" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>

      <div class="view_content_bg_item" @click="openValue('短骨愈合状况')">
        <div class="view_content_bg_item_title">
          <span>短骨愈合状况</span><span>{{ bones11Index }}分</span><span>/100</span><span>{{ bones11 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="bones11" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>

      <div class="view_content_bg_item" @click="openValue('骨骺线')">
        <div class="view_content_bg_item_title">
          <span>骨骺线</span><span>{{ bones12Index }}分</span><span>/100</span><span>{{ bones12 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="bones12" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>


      <div style="height: 28px"/>

    </div>
    <view_bottom></view_bottom>
  </div>
</template>

<script>
import view_bottom from "@/pages/healthy/view_bottom";
import axios from "axios";

export default {
  name: "ReportH",
  components: {view_bottom},
  data() {
    return {
      bones1: "",
      bones2: "",
      bones3: "",
      bones4: "",
      bones5: "",
      bones6: "",
      bones7: "",
      bones8: "",
      bones9: "",
      bones10: "",
      bones11: "",
      bones12: "",

      bones1Index: "",
      bones2Index: "",
      bones3Index: "",
      bones4Index: "",
      bones5Index: "",
      bones6Index: "",
      bones7Index: "",
      bones8Index: "",
      bones9Index: "",
      bones10Index: "",
      bones11Index: "",
      bones12Index: "",

    }
  },
  created() {
    // document.title = '舌诊评估'
    let userId = this.$route.query.userId
    console.log(userId)
    this.getcode(userId)
  },
  methods: {
    getcode(userId) {
      // let url = "https://shezhen.yuanjiwei.cn/api/app/getbones?" + userId
      let url = "https://shezhen.hnxa168.com/newapi/app/getbones?" + userId
      this.br = "<br/>"
      axios.post(url)
      axios.post(url)
          .then((result) => {
            console.log(result)
            this.bones1 = result.data.result.bones1
            this.bones2 = result.data.result.bones2
            this.bones3 = result.data.result.bones3
            this.bones4 = result.data.result.bones4
            this.bones5 = result.data.result.bones5
            this.bones6 = result.data.result.bones6
            this.bones7 = result.data.result.bones7
            this.bones8 = result.data.result.bones8
            this.bones9 = result.data.result.bones9
            this.bones10 = result.data.result.bones10
            this.bones11 = result.data.result.bones11
            this.bones12 = result.data.result.bones12

            this.bones1Index = result.data.result.bones1Index
            this.bones2Index = result.data.result.bones2Index
            this.bones3Index = result.data.result.bones3Index
            this.bones4Index = result.data.result.bones4Index
            this.bones5Index = result.data.result.bones5Index
            this.bones6Index = result.data.result.bones6Index
            this.bones7Index = result.data.result.bones7Index
            this.bones8Index = result.data.result.bones8Index
            this.bones9Index = result.data.result.bones9Index
            this.bones10Index = result.data.result.bones10Index
            this.bones11Index = result.data.result.bones11Index
            this.bones12Index = result.data.result.bones12Index


            this.initBarChart("bones1", this.bones1)
            this.initBarChart("bones2", this.bones2)
            this.initBarChart("bones3", this.bones3)
            this.initBarChart("bones4", this.bones4)
            this.initBarChart("bones5", this.bones5)
            this.initBarChart("bones6", this.bones6)
            this.initBarChart("bones7", this.bones7)
            this.initBarChart("bones8", this.bones8)
            this.initBarChart("bones9", this.bones9)
            this.initBarChart("bones10", this.bones10)
            this.initBarChart("bones11", this.bones11)
            this.initBarChart("bones12", this.bones12)

          }).catch(error => {
        this.$router.replace("error")
      });
    },
    initBarChart(view, count) {
      let jiankang = this.$echarts.init(document.getElementById(view))
      let option = {
        grid: {
          left: '0',
          top: '0',
          right: '0',
          bottom: '0',
          containLabel: true,
          width: '100%'
        },
        xAxis: {
          type: 'value',
          splitLine: {show: false},
          axisLabel: {show: false},
          axisTick: {show: false},
          axisLine: {show: false},

        },
        yAxis: {
          type: 'category',
          axisTick: {show: false},
          axisLine: {show: false},
          axisLabel: {
            color: 'black',
            fontSize: 25,
            show: false,
          },
        },
        series: [
          {
            type: 'bar',
            barWidth: 18,
            data: [count],
            label: {
              //position    : 'middle',
              offset: [20, 2],
              color: '#fff',
              fontSize: 30,
            },
            itemStyle: {
              color: this.$echarts.graphic.LinearGradient(
                  1, 0, 0, 0,
                  [
                    {offset: 0, color: '#16CCBD'},                   //柱图渐变色
                    // {offset: 0.5, color: '#44C0C1'},                 //柱图渐变色
                    {offset: 1, color: '#4BEB76'},                   //柱图渐变色
                  ]
              ),
              barBorderRadius: 9,
            },
            zlevel: 1
          },
          {
            name: '进度条背景',
            type: 'bar',
            barGap: '-100%',
            barWidth: 18,
            data: [100],
            color: '#E3ECF2',//柱条颜色
            itemStyle: {
              normal: {
                barBorderRadius: 9,
                borderColor: '#FEFEFE',
              }
            }
          }
        ]
      };
      jiankang.setOption(option);
    },
    openValue(info){
      this.$router.push({path:"/Healthy/Introduce",query:{userId:info,date:new Date().getTime()}})
    }
  }
}
</script>

<style scoped>

.view_content_bg_item {
  background: #F7FAFA;
  border-radius: 32px;
  margin-top: 24px;
  margin-left: 32px;
  margin-right: 32px;
}

.view_content_bg_item_title {
  padding: 32px 32px 16px;
}

.view_content_bg_item_title > span:nth-child(1) {
  font-size: 28px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  text-align: left;
  vertical-align: top;
}

.view_content_bg_item_title > span:nth-child(2) {
  font-size: 28px;
  font-weight: 400;
  color: rgba(255, 195, 0, 1);
  padding-left: 16px;
  text-align: left;
  vertical-align: top;
}

.view_content_bg_item_title > span:nth-child(3) {
  font-size: 24px;
  font-weight: 400;
  color: rgba(199, 199, 199, 1);
  text-align: center;
  margin-top: 4px;
  padding-left: 6px;
  vertical-align: top;
  float: right;
}

.view_content_bg_item_title > span:nth-child(4) {
  font-size: 28px;
  font-weight: 400;
  color: rgba(23, 203, 188, 1);
  text-align: right;
  vertical-align: top;
  float: right;
}

.view_content_bg_item_buttom {
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 40px;
}
</style>
