<template>
  <div class="view">
    <div class="view_title">
      <div class="view_title_item view_title_item_left">
        <span>生理年龄</span>
        <div class="view_title_text_bg"/>
      </div>

      <div class="view_title_item title_right">
        <div class="view_title_item_riht_show">
          <img src="../../assets/images/shengri_bg.png">
        </div>
        <div class="view_title_item_riht_bg">
          <img src="../../assets/images/zitu_bg_icon.png">
        </div>
      </div>
    </div>

    <div class="view_content_bg">
      <div class="view_content_header">
        <div class="view_title_item view_content_header_top">
          <div class="view_content_header_top_text">您的生理年龄约</div>
          <div class="view_content_header_bottom_text">{{physiologicalAge}}岁</div>
        </div>
        <div class="view_content_header_center"/>
        <div class="view_title_item view_content_header_top">
          <div class="view_content_header_top_text">您的实际年龄</div>
          <div class="view_content_header_bottom_text">{{age}}岁</div>
        </div>
      </div>

      <div class="view_content_center_text">
        图表趋势
      </div>

      <div>
        <div id="myChart" :style="{ width: '100%',height:'350px' }"></div>
      </div>

      <div class="view_content_bottom">
        <div class="view_content_bottom_border">
          <div class="view_content_bottom_border_text">
            <span>心脑血管</span>
            <span>{{bloodVessel}}</span>
          </div>
          <div class="view_content_bottom_border_text">
            <span>{{ ageName }}</span>
            <span>{{ male }}</span>
          </div>
          <div class="view_content_bottom_border_text">
            <span>{{sexName}}</span>
            <span>{{ sex }}</span>
          </div>
          <div class="view_content_bottom_border_text">
            <span>骨骼</span>
            <span>{{bones}}</span>
          </div>
          <div class="view_content_bottom_border_text">
            <span>免疫</span>
            <span>{{immune}}</span>
          </div>
        </div>
        <div class="view_content_bottom_centos">
          <div>年轻</div>
          <div>正常</div>
          <div>衰老</div>
        </div>
        <div class="view_content_bottom_border">
          <div class="view_content_bottom_border_text">
            <span>肠胃</span>
            <span>{{stomach}}</span>
          </div>
          <div class="view_content_bottom_border_text">
            <span>肝胆</span>
            <span>{{hepatobiliary}}</span>
          </div>
          <div class="view_content_bottom_border_text">
            <span>肾脏</span>
            <span>{{ kidney }}</span>
          </div>
          <div class="view_content_bottom_border_text">
            <span>呼吸</span>
            <span>{{ breathing }}</span>
          </div>
          <div class="view_content_bottom_border_text">
            <span>内分泌</span>
            <span>{{endocrine}}</span>
          </div>
        </div>
      </div>
    </div>
    <view_bottom></view_bottom>
  </div>
</template>

<script>
import view_bottom from "@/pages/healthy/view_bottom";
import axios from "axios";
import view from "../../assets/css/view.css"

export default {
  name: "PhysiologicalAge",
  components: {view_bottom},
  data() {
    return {
      indicator: [

      ],
      value: [
      ],
      physiologicalAge:"",
      age:"",
      bloodVessel:"",
      sexName:"",
      stomach:"",
      hepatobiliary:"",
      kidney:"",
      breathing:"",
      endocrine:"",
      immune:"",
      bones:"",
      male:"",
      ageName:"",
      sex:"",
      count:"",
      count_1:"",

    }
  },
  created() {
    // document.title = '舌诊评估'
    let userId = this.$route.query.userId
    console.log(userId)
    this.getcode(userId)
  },
  methods: {
    getcode(userId) {
      let url = "https://shezhen.hnxa168.com/newapi/app/getShengli?" + userId
      // let url = "https://shezhen.yuanjiwei.cn/api/app/getShengli?" + userId
      this.br = "<br/>"
      axios.post(url)
      axios.post(url)
          .then((result) => {
            this.physiologicalAge = result.data.result.physiologicalAge
            this.age = result.data.result.age
            this.bloodVessel = result.data.result.bloodVessel
            if(result.data.result.gender === "1"){
              this.sexName = "男性"
              this.ageName = "前列腺"
            }else{
              this.sexName = "女性"
              this.ageName = "乳腺"
            }
            this.stomach = result.data.result.stomach
            this.hepatobiliary = result.data.result.hepatobiliary
            this.kidney = result.data.result.kidney
            this.breathing = result.data.result.breathing
            this.endocrine = result.data.result.endocrine
            this.immune = result.data.result.immune
            this.bones = result.data.result.bones
            this.male = result.data.result.male
            this.sex = result.data.result.sex

            this.count = parseInt(this.physiologicalAge)*2
            this.count_1 = (parseInt(this.physiologicalAge)*2)+10

            this.indicator = [
              {text: '心脑血管', max: this.count},
              {text: '肠胃', max: this.count},
              {text: '肝胆', max: this.count},
              {text: '肾脏', max: this.count},
              {text: '呼吸', max: this.count},
              {text: '内分泌', max: this.count},
              {text: '免疫', max: this.count},
              {text: '骨骼', max: this.count},
              {text: this.sexName, max: this.count},
              {text: this.ageName, max: this.count_1},
            ]

            this.value = [this.bloodVessel,this.stomach,this.hepatobiliary,this.kidney,this.breathing,
            this.endocrine,this.immune,this.bones,this.sex,this.male]
            this.initBarChart()
          }).catch(error => {
        this.$router.replace("error")
      });
    },
    initBarChart() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('myChart'))
      let i = -1;
      let option = {
        radar:
            {
              indicator: this.indicator,
              radius: 90,
              splitNumber: 3,
              shape: 'circle',
              axisName: {
                formatter: (a, b) => {
                  i++;
                  return `{b|${this.value[i]}}\n{a|${a}}`
                },
                rich: {
                  b: {
                    color: '#17CBBC',
                    width: "50px",
                    padding: 5,
                    fontSize: 14,
                  },
                  a: {
                    color: '#333',
                    lineHeight: 25,
                    padding: 2,
                    borderRadius: 4,
                    fontSize: 14,
                  },
                }
              },
              nameGap: 20,
              splitArea: {
                areaStyle: {
                  color: ['#00AF50', '#FFE9A5', '#ED9C22'],
                  shadowColor: 'rgba(0, 0, 0, 0.2)',
                  shadowBlur: 10
                }
              },
              axisLine: {
                lineStyle: {
                  color: '#fff',
                  width: 3,
                }
              },
            },
        series:
            {
              type: 'radar',
              emphasis: {
                lineStyle: {
                  width: 100
                }
              },
              symbol: 'none',
              data: [
                {
                  value: this.value,
                  lineStyle: {
                    width: 3,
                    color: '#17CBBC'
                  },
                  label: {
                    normal: {
                      show: true,
                      formatter: function (params) {
                        return params.value;
                      }
                    }
                  }
                }
              ]
            }
      };
      // 绘制图表
      myChart.setOption(option);
    },
  }
  ,
}
</script>

<style scoped>


.view_content_header {
  display: flex;
}

.view_content_header_top {
  margin-top: 48px;
  margin-left: 48px;
}

.view_content_header_top_text {
  font-size: 28px;
  font-weight: 400;
  color: rgba(112, 112, 112, 1);
  text-align: left;
  vertical-align: top;
}

.view_content_header_bottom_text {
  font-size: 48px;
  font-weight: 700;
  color: rgba(23, 203, 188, 1);
  text-align: left;
  vertical-align: top;
  margin-top: 8px;
}

.view_content_header_center {
  width: 2px;
  height: 104px;
  background: #CCCCCC;
  margin-top: 56px;
}

.view_content_center_text {
  font-size: 32px;
  font-weight: 700;
  line-height: 28px;
  color: rgba(56, 56, 56, 1);
  text-align: left;
  vertical-align: top;
  margin-left: 32px;
  margin-top: 48px;
}

.view_content_bottom {
  padding: 48px 32px;
  display: flex;
  justify-content: space-between;
}

.view_content_bottom_border {
  width: 40%;
  padding: 0 36px 24px 32px;
  background: #F7FAFA;
  border-radius: 32px;

}

.view_content_bottom_centos {
  width: 20%;
  margin: auto;
}

.view_content_bottom_border_text {
  justify-content: space-between;
  display: flex;
  margin-top: 24px;
}

.view_content_bottom_border_text > span:nth-child(1) {
  font-size: 28px;
  color: #999999;
}

.view_content_bottom_border_text > span:nth-child(2n) {
  color: #000000;
  font-size: 28px;
  font-weight: 700;
}

.view_content_bottom_centos > div {
  height: 48px;
  font-size: 28px;
  color: rgba(255, 255, 255, 1);
  text-align: center;
}

.view_content_bottom_centos > div:nth-child(1) {
  background: #17CBBC;
}

.view_content_bottom_centos > div:nth-child(2) {
  background: #F7CA40;
}

.view_content_bottom_centos > div:nth-child(3) {
  background: #ED9C22;
}
</style>
