<template>
  <div>
    <div class="healthy">
      <div>
        <div class="header">
          <div class="header_title">
            <img :src="top_image" alt=""/>
          </div>
          <div>{{ username }}</div>
        </div>
        <!-- 详情 -->
        <div class="details_list">
          <div class="details">
            <div class="details_item">
              <span>年龄</span>
              <span>{{ age }}岁</span>
            </div>
            <div class="details_item pl30">
              <span>性别</span>
              <span>{{ sex }}</span>
            </div>
          </div>
          <div class="details pt30">
            <div class="details_item">
              <span>身高</span>
              <span>{{ height }}cm</span>
            </div>
            <div class="details_item pl30">
              <span>体重</span>
              <span>{{ weight }}kg</span>
            </div>
          </div>
          <div class="details pt30">
            <div class="details_item">
              <span>心理年龄</span>
              <span>{{ physiologicalAge }}岁</span>
            </div>
            <div class="details_item pl30">
              <span>健康等级</span>
              <span>{{ grade }}</span>
            </div>
          </div>
        </div>
        <!-- 健康状况总评 -->
        <div class="comments">
          <div class="comments_title">
            <span></span>
            <span>健康状况总评</span>
          </div>
          <div class="commentsItem">
            <div class="commentsItem_list" @click="openPhy()">
              <img
                  alt=""
                  src="https://img.js.design/assets/img/6331ab812df3b677e1eb719b.png#7538865964cf75c455941a43234fddbc"
              />
              <div>生理年龄</div>
            </div>
            <div class="commentsItem_list" @click="openHealthy()">
              <img
                  alt=""
                  src="https://img.js.design/assets/img/6331ab807de19a299449c944.png#af12d8234cdc5fc766b815a334d5d879"
              />
              <div>身心状况</div>
            </div>
            <div class="commentsItem_list" @click="openGl()">
              <img
                  alt=""
                  src="../../assets/images/shezhen.png"
              />
              <div>舌诊评估</div>
            </div>
            <div class="commentsItem_list" @click="openHealthServices()">
              <img
                  alt=""
                  src="https://img.js.design/assets/img/6331ab815dc98dd76db59e7c.png#f71a3a2037d28f31774b241e119ffda1"
              />
              <div>健康服务</div>
            </div>
          </div>
        </div>
        <!-- 详细健康评估 -->
        <div class="comments">
          <div class="comments_title">
            <span></span>
            <span>详细健康评估</span>
          </div>
          <div class="commentsItem">
            <div class="assessment" @click="openReportA()">
              <img
                  alt=""
                  src="https://img.js.design/assets/img/6331ab8206e05a288c82ecf6.png#0f30cc960494aea741642a94ec134b0d"
              />
              <div class="assessmentText">{{ bloodVessel }}</div>
              <div class="col9">心脑血管</div>
            </div>
            <div class="assessment" @click="openReportB()">
              <img
                  alt=""
                  src="https://img.js.design/assets/img/6331ab80eee3720f6e856d5f.png#f58318abfc97493db489a07c8ebdf29b"
              />
              <div class="assessmentText">{{ stomach }}</div>
              <div class="col9">肠胃</div>
            </div>
            <div class="assessment" @click="openReportC()">
              <img
                  alt=""
                  src="https://img.js.design/assets/img/6331ab7eebef9722cf4c570e.png#1d95dc89db4af399ab0fcedc7dd0e211"
              />
              <div class="assessmentText">{{ liver }}</div>
              <div class="col9">肝胆</div>
            </div>
            <div class="assessment" @click="openReportD()">
              <img
                  alt=""
                  src="https://img.js.design/assets/img/6331ab7e06e05a288c82ec42.png#d54d385c908ee581a37577ea3097f14f"
              />
              <div class="assessmentText">{{ kidney }}</div>
              <div class="col9">肾脏</div>
            </div>
          </div>
          <div class="commentsItem">
            <div class="assessment" @click="openReportE()">
              <img
                  alt=""
                  src="https://img.js.design/assets/img/6331ab7d2df3b677e1eb708b.png#5cfb539446bb35092f1fdf88342c19e7"
              />
              <div class="assessmentText">{{ breathing }}</div>
              <div class="col9">&#8195;呼吸&#8195;</div>
            </div>
            <div class="assessment" @click="openReportF()">
              <img
                  alt=""
                  src="https://img.js.design/assets/img/6331ab7f06e05a288c82ec91.png#d5b240d2758d34d1757434244f03d095"
              />
              <div class="assessmentText">{{ endocrine }}</div>
              <div class="col9">内分泌</div>
            </div>
            <div class="assessment" @click="openReportG()">
              <img
                  alt=""
                  src="https://img.js.design/assets/img/6331ab7febef9722cf4c5767.png#5f2c148b151ce9e63e94f5d55f017a81"
              />
              <div class="assessmentText">{{ immune }}</div>
              <div class="col9">免疫</div>
            </div>
            <div class="assessment" @click="openReportH()">
              <img
                  alt=""
                  src="https://img.js.design/assets/img/6331ab7f2df3b677e1eb7102.png#2976313bc1e719e308552c4fe0ccf08e"
              />
              <div class="assessmentText">{{ bones }}</div>
              <div class="col9">骨骼</div>
            </div>
          </div>
          <div class="commentsItem">
            <div class="assessment" @click="openReport()">
              <img
                  alt=""
                  src="https://img.js.design/assets/img/6331ab7ec8634d973cf7e56c.png#9c30a9186abb8a8ce89c9b795cf3e494"
              />
              <div class="assessmentText">{{ gynaecology }}</div>
              <div class="col9">妇科男科</div>
            </div>
            <div class="assessment" @click="openReportI()">
              <img
                  alt=""
                  src="https://img.js.design/assets/img/6331ab7f5dc98dd76db59de4.png#bd58e42159b02e93b71b7e853991762f"
              />
              <div class="assessmentText">{{ skin }}</div>
              <div class="col9">皮肤</div>
            </div>
            <div class="assessment" @click="openReportJ()">
              <img
                  alt=""
                  src="https://img.js.design/assets/img/6331ab7ecd8824281334ab0e.png#f219dadc9c23d0d5a83f42a2fbcf2c66"
              />
              <div class="assessmentText">{{ eyes }}</div>
              <div class="col9">眼部</div>
            </div>
            <div class="assessment" @click="openReportK()">
              <img
                  alt=""
                  src="https://img.js.design/assets/img/6331ab7e7de19a299449c8f1.png#9291839ccc84d18b7652f518b0103313"
              />
              <div class="assessmentText">{{ vitamin }}</div>
              <div class="col9">维生素</div>
            </div>
          </div>
        </div>
        <!-- //免责声明 -->
        <div class="statement">
          <div>免责声明</div>
          <span>
                欢迎您使用个人健康评估报告（以下简称：健康报告），本人已经阅读健康报告服务条款并同意本声明。
          </span>
          <h2></h2>
          <span>
            健康报告是针对亚健康人群进行科学化数据指标采集设备，并非医疗器械设备。健康报告所有资料及数据报告仅供参考使用，不作为个人健康状况的医疗目的衡量指标，也不能替代医生和其他医务人员的专业建议。
          </span>
          <h2></h2>
          <span>
            在任何情况下，健康报告所提供数据仅为提升用户体验所用，用户对于其自身健康状况的最终评估仅来自于专业医疗机构的检测手段，健康报告对于采集数据不做任何形式的保证，不保证所提供数据满足用户的要求，对采集结果的安全性、正确性、及时性均不做保证。故本产品不对因使用本品提供的数据而导致之损失或损害承担任何直接或间接责任。
          </span>
          <h2></h2>
          <span>
            健康报告是为亚健康人群设计，相关指标依据人体数据采集系统而成，经过科学及实践测算出来，并非特别针对残缺人士及疾病患者，此类人群使用可能会出现个别指标测量不精准。
          </span>
          <h2></h2>
          <span>
            健康报告推送的内容，数据指标、运动调理、健康食谱、健康设备、调养方法等均作为个人健康管理所需的参考，不作为处方，用户可根据自身情况结合专业诊断结果进行。本产品不对通过健康报告服务得到的或在其信息平台上所推介的产品或服务做任何担保。如用户进行采纳健康报告所推送的内容所产生一切后果，本产品不承担任何责任。
            </span>
          <h2></h2>
          <span>
            健康报告是基于假定用户{result}情况下进行的数据采集，采集结果每时每刻都在发生变化，所以建议测量选择固定时间点，最好是空腹平静心态下测量会更有效。用户通过健康报告获取材料或数据由自己承担风险，同时将对使用此类材料或数据而导致的人身或财产损失承担全部责任。
            </span>
          <h2></h2>
          <span>
            因数据报告里面包含个人隐私问题，当用户分享给它人或朋友圈时，即表示该用户自行同意把隐私公开。
            </span>
          <h2></h2>
          <span>
            本产品重视对用户隐私的保护，用户提供的登记资料及本产品保留的有关若干其他个人资料将受到中国有关隐私的法律法规的保护。非经用户亲自许可或根据相关法律的强制性规定，本产品不会主动的将用户的信息泄露给其他任何第三方。
            </span>
          <h2></h2>
          <span>
            本产品有权在必要时修改服务条款，服务条款一旦发生变动，将会在相关页面上公布修改后的服务条款。如果不同意所改动的内容，用户应主动取消此项服务。如果用户继续使用服务，则视为接受服务条款的变动。
            </span>
          <h2></h2>
          <span>
            凡以任何方式登录健康报告，或对用户使用健康报告、网络平台、与本产品相关的任何内容、服务均视为自愿接受健康报告声明的约束。无论在任何原因下（包括但不限于疏忽原因），对用户或任何人通过使用健康报告所导致的损失或损害（包括直接、间接的损失或损害），责任均由使用者自行承担（除非该等损失系由本产品质量不达标所致）。
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {Toast} from "vant";

export default {
  name: "Details",
  components: {},
  data() {
    return {
      top_image: "",
      username: "",
      age: "",
      height: "",
      weight: "",
      sex: "",
      physiologicalAge: "",
      bloodVessel: "",
      stomach: "",
      liver: "",
      kidney: "",
      breathing: "",
      endocrine: "",
      immune: "",
      bones: "",
      gynaecology: "",
      skin: "",
      eyes: "",
      vitamin: "",
      getqyinfo: "",
      grade: "",
      timerout: ""
    }
  },
  created() {
    // document.title = '健康报告'
    let geturl = window.location.href
    this.getqyinfo = geturl.split('?')[1]
    console.log(this.getqyinfo)
    this.getcode()
  },
  methods: {
    getcode() {
      // let url = "http://xiaoanweb.hnxa168.com/jeecg-boot/app/details?" + this.getqyinfo
      let url = "https://shezhen.hnxa168.com/newapi/app/details?" + this.getqyinfo
      // let url = "https://shezhen.yuanjiwei.cn/api/app/details?" + this.getqyinfo
      axios.post(url)
          .then((result) => {
            console.log(result);
            let user = result.data.result.user
            console.log(user);
            this.username = user.name
            this.age = user.age
            this.weight = user.weight
            this.height = user.height
            let physiological = user.physiologicalAge
            this.physiologicalAge = physiological.toString()
            this.bloodVessel = user.bloodVessel
            this.stomach = user.stomach
            this.liver = user.liver
            this.kidney = user.kidney
            this.breathing = user.breathing
            this.endocrine = user.endocrine
            this.immune = user.immune
            this.bones = user.bones
            this.gynaecology = user.gynaecology
            this.skin = user.skin
            this.eyes = user.eyes
            this.vitamin = user.vitamin
            this.timerout = user.timeout
            if (this.top_image.includes("https://shezhen.hnxa168.com/api/sys/common/static/")) {
              this.top_image = "https://shezhen.hnxa168.com/api/sys/common/static/" + user.headPortrait
            } else {
              this.top_image = user.headPortrait;
            }
            // this.top_image = "https://shezhen.yuanjiwei.cn/api/sys/common/static/" + user.headPortrait
            if (user.sex === "1") {
              this.sex = "男"
            } else {
              this.sex = "女"
            }
            let grades = parseInt(this.bloodVessel) + parseInt(this.stomach) + parseInt(this.liver) +
                parseInt(this.kidney) + parseInt(this.breathing) + parseInt(this.endocrine) + parseInt(this.immune) +
                parseInt(this.bones) + parseInt(this.gynaecology) + parseInt(this.skin) + parseInt(this.eyes) + parseInt(this.vitamin)

            let grades_count = grades / 12
            if (grades_count > 90) {
              this.grade = "5"
            } else if (grades_count > 85) {
              this.grade = "4"
            } else {
              this.grade = "3"
            }

          }).catch(error => {
        this.$router.replace("error")
      });
    },
    openGl() {
      switch (this.timerout) {
        case "90":
          Toast("未做舌诊报告")
          break;
        case "270":
          this.$router.push({path: "/Healthy/GlossoscopyNew", query: {userId: this.getqyinfo}})
          break;
        default:
          this.$router.push({path: "/Healthy/Glossoscopy", query: {userId: this.getqyinfo}})
          break;
      }
    },
    openPhy() {
      this.$router.push({path: "/Healthy/PhysiologicalAge", query: {userId: this.getqyinfo}})
    },
    openHealthy() {

      this.$router.push({path: "/Healthy/Healthymind", query: {userId: this.getqyinfo}})
    },
    openHealthServices() {
      switch (this.timerout) {
        case "90":
          Toast("未做舌诊报告")
          break;
        case "270":
          this.$router.push({path: "/Healthy/HealthServicesNew", query: {userId: this.getqyinfo}})
          break;
        default:
          this.$router.push({path: "/Healthy/HealthServices", query: {userId: this.getqyinfo}})
          break;
      }
    },
    openReportA() {
      this.$router.push({path: "/Healthy/ReportA", query: {userId: this.getqyinfo}})
    },
    openReportB() {
      this.$router.push({path: "/Healthy/ReportB", query: {userId: this.getqyinfo}})
    },
    openReportC() {
      this.$router.push({path: "/Healthy/ReportC", query: {userId: this.getqyinfo}})
    },
    openReportD() {
      this.$router.push({path: "/Healthy/ReportD", query: {userId: this.getqyinfo}})
    },
    openReportE() {
      this.$router.push({path: "/Healthy/ReportE", query: {userId: this.getqyinfo}})
    },
    openReportF() {
      this.$router.push({path: "/Healthy/ReportF", query: {userId: this.getqyinfo}})
    },
    openReportG() {
      this.$router.push({path: "/Healthy/ReportG", query: {userId: this.getqyinfo}})
    },
    openReportH() {
      this.$router.push({path: "/Healthy/ReportH", query: {userId: this.getqyinfo}})
    },
    openReportI() {
      this.$router.push({path: "/Healthy/ReportI", query: {userId: this.getqyinfo}})
    },
    openReportJ() {
      this.$router.push({path: "/Healthy/ReportJ", query: {userId: this.getqyinfo}})
    },
    openReportK() {
      this.$router.push({path: "/Healthy/ReportK", query: {userId: this.getqyinfo}})
    },
    openReport() {
      if (this.sex === "男") {
        this.$router.push({path: "/Healthy/ReportNan", query: {userId: this.getqyinfo}})
      } else {
        this.$router.push({path: "/Healthy/ReportNv", query: {userId: this.getqyinfo}})
      }
    }
  }
}
</script>

<style scoped>
.statement {
  padding-top: 50px;
}

.statement > div:nth-child(1) {
  font-size: 35px;
  color: #ffffff;
  font-weight: 650;
  padding-bottom: 30px;
}

.statement > span {
  color: #ffffff;
  line-height: 50px;
}

.col9 {
  color: #999999 !important;
}

.assessmentText {
  color: rgba(215, 100, 35, 1) !important;
  font-weight: 700 !important;
  font-size: 26px;
  padding: 20px 0;
}

.assessment > img {
  width: 75px;
  height: 75px;
  display: block;
}

.assessment {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.commentsItem_list {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.commentsItem_list > img {
  width: 88px;
  height: 88px;
  display: block;
}

.commentsItem_list > div {
  margin-top: 20px;
  font-size: 24px;
  color: #000000;
  font-weight: 650;
}

.commentsItem {
  display: flex;
  justify-content: space-between;
}

/* .commentsItem{width: 25%;} */
.comments_title {
  display: flex;
  align-items: center;
}

.comments_title > span:nth-child(1) {
  width: 15px;
  height: 15px;
  background: rgba(1, 183, 171, 1);
  border-radius: 50%;
  display: block;
  margin-right: 15px;
}

.comments_title > span:nth-child(2) {
  font-size: 30px;
  color: #000000;
  font-weight: 700;
}

/deep/ .van-nav-bar .van-icon {
  color: black !important;
}

.pl30 {
  padding-left: 50px;
}

.pt30 {
  padding-top: 50px;
}

.header_title {
  background-color: #ffffff;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
  margin-top: -80px;
  display: flex;
  justify-content: center;
}

.healthy {
  /* background: url(https://img.js.design/assets/img/6330750235a21bf1cb3e9f31.png#84e217802a762075630feb094c1b1025); */
  background: rgba(0, 186, 173, 1);
  width: 100%;
  /* height: 100vh; */
  padding: 1rem 0.4rem 0.4rem 0.4rem;
  /* background-size: 100% 100% !important; */
}

.header {
  background-color: #ffffff;
  border-radius: 25px 25px 0 0;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header > div:nth-child(2) {
  font-size: 32px;
  font-weight: 700;
  color: #000000;
  margin-top: 20px;
}

.header_title > img {
  width: 128px;
  height: 128px;
  margin-top: 11px;
  border-radius: 50%;

}

.details_list {
  background-color: #ffffff;
  border-radius: 0 0 25px 25px;
  padding: 20px 45px 45px 45px;
}

.details {
  display: flex;
  /* justify-content: space-between; */
}

.details_item {
  width: 50%;
  display: flex;
  justify-content: space-between;
}

.details_item > span:nth-child(1) {
  font-size: 28px;
  color: #999999;
}

.details_item > span:nth-child(2n) {
  color: #000000;
  font-size: 28px;
  font-weight: 700;
}

.comments {
  margin-top: 30px;
  background-color: #ffffff;
  border-radius: 30px;
  padding: 45px 30px;
}
</style>
