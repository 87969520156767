<template>
  <div class="view">
    <div class="view_title">
      <div class="view_title_item view_title_item_left">
        <span>呼吸</span>
        <div class="view_title_text_bg"/>
      </div>

      <div class="view_title_item title_right">
        <div class="view_title_item_riht_show">
          <img src="../../assets/images/huxi.png">
        </div>
        <div class="view_title_item_riht_bg">
          <img src="../../assets/images/zitu_bg_icon.png">
        </div>
      </div>
    </div>
    <div class="view_content_bg">
      <div class="view_content_bg_title">详情数据</div>

      <div class="view_content_bg_item" @click="openValue('肺活量')">
        <div class="view_content_bg_item_title">
          <span>肺活量</span><span>{{ vitalCapacityIndex }}分</span><span>/100</span><span>{{ vitalCapacity }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="vitalCapacity" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>
      <div class="view_content_bg_item" @click="openValue('肺总量')">
        <div class="view_content_bg_item_title">
          <span>肺总量</span><span>{{ totalLungVolumeIndex }}分</span><span>/100</span><span>{{ totalLungVolume }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="totalLungVolume" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>
      <div class="view_content_bg_item" @click="openValue('气道阻力')">
        <div class="view_content_bg_item_title">
          <span>气道阻力</span><span>{{ airwayResistanceIndex }}分</span><span>/100</span><span>{{ airwayResistance }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="airwayResistance" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>
      <div class="view_content_bg_item" @click="openValue('动脉血氧含量')">
        <div class="view_content_bg_item_title">
          <span>动脉血氧含量</span><span>{{
            arterialOxygenContentIndex
          }}分</span><span>/100</span><span>{{ arterialOxygenContent }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="arterialOxygenContent" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>
      <div class="view_content_bg_item"  @click="openValue('睡眠呼吸')">
        <div class="view_content_bg_item_title">
          <span>睡眠呼吸</span><span>{{ sleepBreathingIndex }}分</span><span>/100</span><span>{{ sleepBreathing }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="sleepBreathing" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>


      <div style="height: 28px"/>

    </div>
    <view_bottom></view_bottom>
  </div>
</template>

<script>
import view_bottom from "@/pages/healthy/view_bottom";
import axios from "axios";

export default {
  name: "ReportE",
  components: {view_bottom},
  data() {
    return {
      vitalCapacity: "",
      vitalCapacityIndex: "",
      totalLungVolume: "",
      totalLungVolumeIndex: "",
      airwayResistance: "",
      airwayResistanceIndex: "",
      arterialOxygenContent: "",
      arterialOxygenContentIndex: "",
      sleepBreathing: "",
      sleepBreathingIndex: "",
    }
  },
  created() {
    // document.title = '舌诊评估'
    let userId = this.$route.query.userId
    console.log(userId)
    this.getcode(userId)
  },
  methods: {
    getcode(userId) {
      let url = "https://shezhen.hnxa168.com/newapi/app/getBreathing?" + userId
      // let url = "https://shezhen.yuanjiwei.cn/api/app/getBreathing?" + userId
      this.br = "<br/>"
      axios.post(url)
      axios.post(url)
          .then((result) => {
            console.log(result)
            this.vitalCapacity = result.data.result.vitalCapacity
            this.vitalCapacityIndex = result.data.result.vitalCapacityIndex
            this.totalLungVolume = result.data.result.totalLungVolume
            this.totalLungVolumeIndex = result.data.result.totalLungVolumeIndex
            this.airwayResistance = result.data.result.airwayResistance
            this.airwayResistanceIndex = result.data.result.airwayResistanceIndex
            this.arterialOxygenContent = result.data.result.arterialOxygenContent
            this.arterialOxygenContentIndex = result.data.result.arterialOxygenContentIndex
            this.sleepBreathing = result.data.result.sleepBreathing
            this.sleepBreathingIndex = result.data.result.sleepBreathingIndex

            this.initBarChart("vitalCapacity", this.vitalCapacity)
            this.initBarChart("totalLungVolume", this.totalLungVolume)
            this.initBarChart("airwayResistance", this.airwayResistance)
            this.initBarChart("arterialOxygenContent", this.arterialOxygenContent)
            this.initBarChart("sleepBreathing", this.sleepBreathing)

          }).catch(error => {
        this.$router.replace("error")
      });
    },
    initBarChart(view, count) {
      let jiankang = this.$echarts.init(document.getElementById(view))
      let option = {
        grid: {
          left: '0',
          top: '0',
          right: '0',
          bottom: '0',
          containLabel: true,
          width: '100%'
        },
        xAxis: {
          type: 'value',
          splitLine: {show: false},
          axisLabel: {show: false},
          axisTick: {show: false},
          axisLine: {show: false},

        },
        yAxis: {
          type: 'category',
          axisTick: {show: false},
          axisLine: {show: false},
          axisLabel: {
            color: 'black',
            fontSize: 25,
            show: false,
          },
        },
        series: [
          {
            type: 'bar',
            barWidth: 18,
            data: [count],
            label: {
              //position    : 'middle',
              offset: [20, 2],
              color: '#fff',
              fontSize: 30,
            },
            itemStyle: {
              color: this.$echarts.graphic.LinearGradient(
                  1, 0, 0, 0,
                  [
                    {offset: 0, color: '#16CCBD'},                   //柱图渐变色
                    // {offset: 0.5, color: '#44C0C1'},                 //柱图渐变色
                    {offset: 1, color: '#4BEB76'},                   //柱图渐变色
                  ]
              ),
              barBorderRadius: 9,
            },
            zlevel: 1
          },
          {
            name: '进度条背景',
            type: 'bar',
            barGap: '-100%',
            barWidth: 18,
            data: [100],
            color: '#E3ECF2',//柱条颜色
            itemStyle: {
              normal: {
                barBorderRadius: 9,
                borderColor: '#FEFEFE',
              }
            }
          }
        ]
      };
      jiankang.setOption(option);
    },
    openValue(info){
      this.$router.push({path:"/Healthy/Introduce",query:{userId:info,date:new Date().getTime()}})
    }
  }
}
</script>

<style scoped>

.view_content_bg_item {
  background: #F7FAFA;
  border-radius: 32px;
  margin-top: 24px;
  margin-left: 32px;
  margin-right: 32px;
}

.view_content_bg_item_title {
  padding: 32px 32px 16px;
}

.view_content_bg_item_title > span:nth-child(1) {
  font-size: 28px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  text-align: left;
  vertical-align: top;
}

.view_content_bg_item_title > span:nth-child(2) {
  font-size: 28px;
  font-weight: 400;
  color: rgba(255, 195, 0, 1);
  padding-left: 16px;
  text-align: left;
  vertical-align: top;
}

.view_content_bg_item_title > span:nth-child(3) {
  font-size: 24px;
  font-weight: 400;
  color: rgba(199, 199, 199, 1);
  text-align: center;
  margin-top: 4px;
  padding-left: 6px;
  vertical-align: top;
  float: right;
}

.view_content_bg_item_title > span:nth-child(4) {
  font-size: 28px;
  font-weight: 400;
  color: rgba(23, 203, 188, 1);
  text-align: right;
  vertical-align: top;
  float: right;
}

.view_content_bg_item_buttom {
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 40px;
}
</style>
