<template>
  <div class="view">
    <div class="view_title">
      <div class="view_title_item view_title_item_left">
        <span>免疫</span>
        <div class="view_title_text_bg"/>
      </div>

      <div class="view_title_item title_right">
        <div class="view_title_item_riht_show">
          <img src="../../assets/images/mianyi.png">
        </div>
        <div class="view_title_item_riht_bg">
          <img src="../../assets/images/zitu_bg_icon.png">
        </div>
      </div>
    </div>
    <div class="view_content_bg">
      <div class="view_content_bg_title">详情数据</div>

      <div class="view_content_bg_item" @click="openValue('淋巴结')">
        <div class="view_content_bg_item_title">
          <span>淋巴结</span><span>{{ immune1Index }}分</span><span>/100</span><span>{{ immune1 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="immune1" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>

      <div class="view_content_bg_item" @click="openValue('扁桃体免疫')">
        <div class="view_content_bg_item_title">
          <span>扁桃体免疫</span><span>{{ immune2Index }}分</span><span>/100</span><span>{{ immune2 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="immune2" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>
      <div class="view_content_bg_item" @click="openValue('骨髓')">
        <div class="view_content_bg_item_title">
          <span>骨髓</span><span>{{ immune3Index }}分</span><span>/100</span><span>{{ immune3 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="immune3" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>
      <div class="view_content_bg_item" @click="openValue('脾脏')">
        <div class="view_content_bg_item_title">
          <span>脾脏</span><span>{{ immune4Index }}分</span><span>/100</span><span>{{ immune4 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="immune4" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>
      <div class="view_content_bg_item" @click="openValue('胸腺')">
        <div class="view_content_bg_item_title">
          <span>胸腺</span><span>{{ immune5Index }}分</span><span>/100</span><span>{{ immune5 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="immune5" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>
      <div class="view_content_bg_item" @click="openValue('免疫球蛋白')">
        <div class="view_content_bg_item_title">
          <span>免疫球蛋白</span><span>{{ immune6Index }}分</span><span>/100</span><span>{{ immune6 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="immune6" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>
      <div class="view_content_bg_item" @click="openValue('呼吸道免疫')">
        <div class="view_content_bg_item_title">
          <span>呼吸道免疫</span><span>{{ immune7Index }}分</span><span>/100</span><span>{{ immune7 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="immune7" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>
      <div class="view_content_bg_item" @click="openValue('消化道免疫')">
        <div class="view_content_bg_item_title">
          <span>消化道免疫</span><span>{{ immune8Index }}分</span><span>/100</span><span>{{ immune8 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="immune8" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>
      <div class="view_content_bg_item" @click="openValue('粘膜内免疫')">
        <div class="view_content_bg_item_title">
          <span>粘膜内免疫</span><span>{{ immune9Index }}分</span><span>/100</span><span>{{ immune9 }}</span>
        </div>
        <div class="view_content_bg_item_buttom">
          <div id="immune9" :style="{ width: '100%',height:'16px' }"></div>
        </div>
      </div>

      <div style="height: 28px"/>

    </div>
    <view_bottom></view_bottom>
  </div>
</template>

<script>
import view_bottom from "@/pages/healthy/view_bottom";
import axios from "axios";

export default {
  name: "ReportG",
  components: {view_bottom},
  data() {
    return {
      immune1: "",
      immune2: "",
      immune3: "",
      immune4: "",
      immune5: "",
      immune6: "",
      immune7: "",
      immune8: "",
      immune9: "",

      immune1Index: "",
      immune2Index: "",
      immune3Index: "",
      immune4Index: "",
      immune5Index: "",
      immune6Index: "",
      immune7Index: "",
      immune8Index: "",
      immune9Index: "",

    }
  },
  created() {
    // document.title = '舌诊评估'
    let userId = this.$route.query.userId
    console.log(userId)
    this.getcode(userId)
  },
  methods: {
    getcode(userId) {
      let url = "https://shezhen.hnxa168.com/newapi/app/getiImmune?" + userId
      // let url = "https://shezhen.yuanjiwei.cn/api/app/getiImmune?" + userId
      this.br = "<br/>"
      axios.post(url)
      axios.post(url)
          .then((result) => {
            console.log(result)
            this.immune1 = result.data.result.immune1
            this.immune2 = result.data.result.immune2
            this.immune3 = result.data.result.immune3
            this.immune4 = result.data.result.immune4
            this.immune5 = result.data.result.immune5
            this.immune6 = result.data.result.immune6
            this.immune7 = result.data.result.immune7
            this.immune8 = result.data.result.immune8
            this.immune9 = result.data.result.immune9

            this.immune1Index = result.data.result.immune1Index
            this.immune2Index = result.data.result.immune2Index
            this.immune3Index = result.data.result.immune3Index
            this.immune4Index = result.data.result.immune4Index
            this.immune5Index = result.data.result.immune5Index
            this.immune6Index = result.data.result.immune6Index
            this.immune7Index = result.data.result.immune7Index
            this.immune8Index = result.data.result.immune8Index
            this.immune9Index = result.data.result.immune9Index


            this.initBarChart("immune1", this.immune1)
            this.initBarChart("immune2", this.immune2)
            this.initBarChart("immune3", this.immune3)
            this.initBarChart("immune4", this.immune4)
            this.initBarChart("immune5", this.immune5)
            this.initBarChart("immune6", this.immune6)
            this.initBarChart("immune7", this.immune7)
            this.initBarChart("immune8", this.immune8)
            this.initBarChart("immune9", this.immune9)

          }).catch(error => {
        this.$router.replace("error")
      });
    },
    initBarChart(view, count) {
      let jiankang = this.$echarts.init(document.getElementById(view))
      let option = {
        grid: {
          left: '0',
          top: '0',
          right: '0',
          bottom: '0',
          containLabel: true,
          width: '100%'
        },
        xAxis: {
          type: 'value',
          splitLine: {show: false},
          axisLabel: {show: false},
          axisTick: {show: false},
          axisLine: {show: false},

        },
        yAxis: {
          type: 'category',
          axisTick: {show: false},
          axisLine: {show: false},
          axisLabel: {
            color: 'black',
            fontSize: 25,
            show: false,
          },
        },
        series: [
          {
            type: 'bar',
            barWidth: 18,
            data: [count],
            label: {
              //position    : 'middle',
              offset: [20, 2],
              color: '#fff',
              fontSize: 30,
            },
            itemStyle: {
              color: this.$echarts.graphic.LinearGradient(
                  1, 0, 0, 0,
                  [
                    {offset: 0, color: '#16CCBD'},                   //柱图渐变色
                    // {offset: 0.5, color: '#44C0C1'},                 //柱图渐变色
                    {offset: 1, color: '#4BEB76'},                   //柱图渐变色
                  ]
              ),
              barBorderRadius: 9,
            },
            zlevel: 1
          },
          {
            name: '进度条背景',
            type: 'bar',
            barGap: '-100%',
            barWidth: 18,
            data: [100],
            color: '#E3ECF2',//柱条颜色
            itemStyle: {
              normal: {
                barBorderRadius: 9,
                borderColor: '#FEFEFE',
              }
            }
          }
        ]
      };
      jiankang.setOption(option);
    },
    openValue(info){
      this.$router.push({path:"/Healthy/Introduce",query:{userId:info,date:new Date().getTime()}})
    }
  }
}
</script>

<style scoped>

.view_content_bg_item {
  background: #F7FAFA;
  border-radius: 32px;
  margin-top: 24px;
  margin-left: 32px;
  margin-right: 32px;
}

.view_content_bg_item_title {
  padding: 32px 32px 16px;
}

.view_content_bg_item_title > span:nth-child(1) {
  font-size: 28px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  text-align: left;
  vertical-align: top;
}

.view_content_bg_item_title > span:nth-child(2) {
  font-size: 28px;
  font-weight: 400;
  color: rgba(255, 195, 0, 1);
  padding-left: 16px;
  text-align: left;
  vertical-align: top;
}

.view_content_bg_item_title > span:nth-child(3) {
  font-size: 24px;
  font-weight: 400;
  color: rgba(199, 199, 199, 1);
  text-align: center;
  margin-top: 4px;
  padding-left: 6px;
  vertical-align: top;
  float: right;
}

.view_content_bg_item_title > span:nth-child(4) {
  font-size: 28px;
  font-weight: 400;
  color: rgba(23, 203, 188, 1);
  text-align: right;
  vertical-align: top;
  float: right;
}

.view_content_bg_item_buttom {
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 40px;
}
</style>
