<template>
  <div>
    <div class="glossoscopy">
      <div class="glossoscopyList">
        <div class="commentsItem">

          <div class="assessment">
            <img
                :src="ossOriImgUrl"
                alt=""
            />
            <div class="col9 pt30">舌面原图矫正图</div>
          </div>

          <div class="assessment">
            <img
                :src="ossSplitBackImgUrl"
                alt=""
            />

            <div class="col9 pt30">舌面原图矫正图</div>
          </div>
        </div>
        <div class="diagnostic_results_view">
          <span>诊断结果：{{ diagnostic_results }}</span>
        </div>

        <span class="fz24" v-html="veinDescribe"/>
      </div>

      <div class="glossoscopyList">
        <div class="glossoscopytitle_one">
          <!-- <img src="../../assets/images/assessment1.png" class="glossoscopytex" alt=""> -->
          <img alt="" class="glossoscopytex" src="../../assets/images/assessment1.png">
          <span class="glossoscopytitle">津液</span>
        </div>
        <div class="glossoscopytitle_two">{{ bodyfluidNames }}</div>
        <span class="fz24" v-html="bodyfluidDescribe"
        ></span
        >
      </div>
      <div class="glossoscopyList">
        <div class="glossoscopytitle_one">
          <img alt="" class="glossoscopytex" src="../../assets/images/assessment2.png">
          <span class="glossoscopytitle">苔色</span>
        </div>
        <div class="glossoscopytitle_two">{{ colorOfMossNames }}</div>
        <span class="fz24" v-html="colorOfMossDescribe"></span
        >
      </div>
      <div class="glossoscopyList">
        <div class="glossoscopytitle_one">
          <img alt="" class="glossoscopytex" src="../../assets/images/assessment3.png">
          <span class="glossoscopytitle">舌色</span>
        </div>
        <div class="glossoscopytitle_two">{{ colorOfTongueNames }}</div>
        <span class="fz24" v-html="colorOfTongueDescribe"/>
      </div>

      <div class="glossoscopyList">
        <div class="glossoscopytitle_one">
          <img alt="" class="glossoscopytex" src="../../assets/images/assessment4.png">
          <span class="glossoscopytitle">苔质</span>
        </div>
        <div class="glossoscopytitle_two">{{ mossNames }}</div>
        <span class="fz24" v-html="mossDescribe"/>
      </div>

      <div class="glossoscopyList">
        <div class="glossoscopytitle_one">
          <img alt="" class="glossoscopytex" src="../../assets/images/assessment5.png">
          <span class="glossoscopytitle">舌形</span>
        </div>
        <div class="glossoscopytitle_two">{{ shapeOfTongueNames }}</div>
        <span class="fz24" v-html="shapeOfTongueDescribe"/>
      </div>

      <div class="glossoscopyList">
        <div class="glossoscopytitle_one">
          <img alt="" class="glossoscopytex" src="../../assets/images/assessment6.png">
          <span class="glossoscopytitle">络脉</span>
        </div>
        <div class="glossoscopytitle_two">{{ veinNames }}</div>
        <span class="fz24" v-html="veinDescribe"/>
      </div>


      <div class="glossoscopyList">
        <div class="glossoscopytitle_one">
          <img alt="" class="glossoscopytex" src="../../assets/images/assessment10.png">
          <span class="glossoscopytitle">易患病症</span>
        </div>
        <div class="explain">说明</div>
        <span class="fz24">{{ predisposition }}</span>
      </div>


      <div class="glossoscopyList">
        <div class="glossoscopytitle_one">
          <img alt="" class="glossoscopytex" src="../../assets/images/assessment12.png">
          <span class="glossoscopytitle">主要表现</span>
        </div>
        <div class="explain">说明</div>
        <span class="fz24">{{ mainManifestations }}</span>
      </div>

      <div class="glossoscopyList">
        <div class="glossoscopytitle_one">
          <img alt="" class="glossoscopytex" src="../../assets/images/assessment13.png">
          <span class="glossoscopytitle">发生原因</span>
        </div>
        <div class="explain">说明</div>
        <span class="fz24" v-html="occurReason"></span>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "Healthy",
  data() {
    return {
      bodyfluidNames: "",
      bodyfluidDescribe: "",
      colorOfMossNames: "",
      colorOfMossDescribe: "",
      colorOfTongueNames: "",
      colorOfTongueDescribe: "",
      mossNames: "",
      mossDescribe: "",
      shapeOfTongueNames: "",
      shapeOfTongueDescribe: "",
      mainManifestations: "",
      veinNames: "",
      veinDescribe: "",
      constitutionNames: "",
      constitutionDescribe: "",
      singleConfigName: "",
      ossOriImgUrl: "",
      ossSplitImgUrl: "",
      occurReason: "",
      ossSplitBackImgUrl: "",
      diagnostic_results: "",
      br: "",
      loading: false,
      finished: true,
      sports_health_care: [{
        message: "",
        items: [{
          name: "",
          photo: "",
          desc: ""
        }]
      }],
      diet_rehabilitation: [{
        message: "",
        items: [{
          name: "",
          photo: "",
          desc: ""
        }]
      }],
      predisposition: "",
      massage_health_care: [
        {
          message: "",
          items: [{
            name: "",
            photo: "",
            desc: ""
          }]
        }
      ],
      main_performance: [
        {
          message: "",
          items: [{
            name: "",
            photo: "",
            desc: ""
          }]
        }
      ],
      occur_reason: [
        {
          message: "",
          items: [{
            name: "",
            photo: "",
            desc: ""
          }]
        }
      ],
      drug_health_care: [
        {
          message: "",
          items: [{
            name: "",
            photo: "",
            desc: ""
          }]
        }
      ],
      recuperates: [
        {
          message: "",
          items: [{
            name: "",
            photo: "",
            desc: ""
          }]
        }
      ],
    };
  },
  created() {
    // document.title = '舌诊评估'
    let userId = this.$route.query.userId
    console.log(userId)
    this.getcode(userId)
  },
  methods: {
    getcode(userId) {
      let url = "https://shezhen.hnxa168.com/newapi/app/details?" + userId
      // let url = "https://shezhen.yuanjiwei.cn/api/app/details?" + userId
      this.br = "<br/>"
      axios.post(url)
          .then((result) => {
            console.log(result);
            let details = result.data.result.details
            let json = JSON.parse(details.treatplanjson)
            console.log(json);
            this.diagnostic_results = json.constitution;
            this.ossOriImgUrl = json.upperTongueImageUrl;
            this.ossSplitBackImgUrl = json.lowerTongueImageUrl;
            this.bodyfluidNames = json.bodyFluid.name
            this.bodyfluidDescribe = json.bodyFluid.nameDesc + "</br>" + json.bodyFluid.pathologicalReason + "</br>"
            this.colorOfMossNames = json.coatingColor.name
            this.colorOfMossDescribe = json.coatingColor.nameDesc + "</br>" + json.coatingColor.pathologicalReason + "</br>"
            this.colorOfTongueNames = json.tongueColor.name
            this.colorOfTongueDescribe = json.tongueColor.nameDesc + "</br>" + json.tongueColor.pathologicalReason + "</br>"
            let mossNamesData = ""
            let mossdescribeData = ""
            for (let i = 0; i < json.coatingQuality.length; i++) {
              if (i === 0) {
                mossNamesData = json.coatingQuality[i].name
                mossdescribeData = json.coatingQuality[i].nameDesc + "</br>" + json.coatingQuality[i].pathologicalReason + "</br>"
              } else {
                mossNamesData = mossNamesData + "," + json.coatingQuality[i].name
                mossdescribeData = mossdescribeData + json.coatingQuality[i].nameDesc + "</br>" + json.coatingQuality[i].pathologicalReason + "</br>"
              }
            }
            this.mossNames = mossNamesData
            this.mossDescribe = mossdescribeData
            let shapeOfTongueData = ""
            let shapeOfTongueDescribeData = ""
            for (let i = 0; i < json.tongueShape.length; i++) {
              if (i === 0) {
                shapeOfTongueData = json.tongueShape[i].name
                shapeOfTongueDescribeData = json.tongueShape[i].nameDesc + "</br>" + json.tongueShape[i].pathologicalReason + "</br>"
              } else {
                shapeOfTongueData = shapeOfTongueData + "," + json.tongueShape[i].name
                shapeOfTongueDescribeData = shapeOfTongueDescribeData + json.tongueShape[i].nameDesc + "</br>" + json.tongueShape[i].pathologicalReason + "</br>"
              }
            }
            this.shapeOfTongueNames = shapeOfTongueData
            this.shapeOfTongueDescribe = shapeOfTongueDescribeData
            this.veinNames = json.vein.name
            this.veinDescribe = json.vein.nameDesc + "</br>" + json.vein.pathologicalReason + "</br>"
            this.predisposition = json.treatplanjson.predisposition
            this.mainManifestations = json.treatplanjson.mainPerformance
            let occurReasonData = ""
            for (let i = 0; i < json.treatplanjson.occurReason.length; i++) {
              if (i === 0) {
                occurReasonData = json.treatplanjson.occurReason[i] + "</br>"
              } else {
                occurReasonData = occurReasonData + json.treatplanjson.occurReason[i] + "</br>"
              }
            }
            this.occurReason = occurReasonData
          }).catch(error => {
        this.$router.replace("error")
      });
    },
  },
};
</script>
<style scoped>
.pl30 {
  padding-left: 30px;
}

.fz28 {
  color: #000000;
  font-size: 30px;
}

.fz20 {
  font-size: 20px;
  color: #999999;
}

.p15 {
  padding: 10px;
}

.Recuperation {
  display: flex;
  align-items: center;
  margin-top: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: rgba(245, 245, 245, 1);
  border-radius: 15px;
}

.Recuperation > img {
  width: 120px;
  height: 120px;
  display: block;
  border-radius: 25px;
}

.explain {
  font-size: 28px;
  color: rgba(56, 56, 56, 1);
  padding: 20px 0;
}

.glossoscopyimg > img {
  width: 160px;
  height: 160px;
  display: block;
  border-radius: 50%;
}

.col9 {
  color: #999999;
}

.df {
  display: flex;
}

.jub {
  justify-content: space-between;
}

.pt30 {
  padding-top: 30px;
}

.coltt {
  color: rgba(0, 186, 173, 1);
}

.al {
  align-items: center;
}

.fz25 {
  font-size: 25px;
}

.fz24 {
  font-size: 26px;
  color: #999999;
  line-height: 45px;
  padding-top: 30px;
}

.glossoscopytitle_two {
  font-size: 40px;
  color: rgba(0, 186, 173, 1);
  font-weight: 650;
  text-align: center;
  /* border-bottom: 6px solid rgba(0, 186, 173, 1); */
  /* width: 50px; */
  margin: 0 auto 30px auto;
  /* padding-bottom: 45px; */
}

.glossoscopytitle_one {
  display: flex;
  align-items: center;
}

.glossoscopytex {
  width: 30px;
  height: 30px;
  /* background-color: #000000; */
  /* border-radius: 50%; */
  display: block;
  margin-right: 10px;
}

.glossoscopyList {
  background-color: #ffffff;
  border-radius: 25px;
  padding: 30px;
  margin-bottom: 30px;
}

.glossoscopytitle {
  font-size: 26px;
  color: #000000;
  font-weight: 650;
}

.glossoscopy {
  padding: 0.4rem;
  background: rgba(0, 186, 173, 1);
}

/deep/ .van-nav-bar .van-icon {
  color: black !important;
}

.assessment > img {
  transform: rotate(-90deg);
  width: 160px;
  height: 160px;
  display: block;
  border-radius: 50%;
}

.assessment {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.commentsItem {
  display: flex;
  margin-left: 50px;
  margin-right: 50px;
  justify-content: space-between;
}

.diagnostic_results_view {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

.diagnostic_results_view span {
  padding: 8px 24px;
  border-radius: 38px;
  background: rgba(217, 250, 248, 1);

  font-size: 36px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 24px;
  color: rgba(0, 186, 173, 1);
  vertical-align: top;
}
</style>
